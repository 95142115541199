export default {
  data() {
    return {
      timerId: null,
    }
  },
  mounted() {
    this.timerId = setTimeout(
      () => this.$router.push({ name: 'terminal-carNumber' }),
      30000,
    )
  },
  beforeDestroy() {
    clearTimeout(this.timerId)
  },
}
